<template>
    <div>
        <div v-if="!readOnly">
            <b-form-group :id="`input-group-file-uploader-${_uid}`"
                          label="Ladda upp fil(er)"
                          :label-for="`input-file-uploader-${_uid}`">
                <b-form-file :id="`input-file-uploader-${_uid}`"
                             v-model="files"
                             multiple
                             drop-placeholder="Släpp här"
                             placeholder="Klicka eller dra en fil hit"
                             :disabled="isSubmitting"
                             @input="onFilesChange" />
            </b-form-group>

            <!-- Save File - Validation errors -->
            <b-alert :show="validationByKey('SaveFile').length > 0" variant="danger">
                <span>
                    Det gick inte ladda upp följande fil{{validationByKey('SaveFile').length > 1 ? 'er' : ''}}
                </span>
                <ul class="text-danger mb-0">
                    <li v-for="message in validationByKey('SaveFile')" :key="message">
                        {{ message }}
                    </li>
                </ul>
                <div v-if="allowedExtensions.length > 0">
                    <hr />
                    <span>Tänk på att enbart följande filtyper är accepterade:</span><br />
                    <span v-for="(ext, index) in allowedExtensions" :key="index">
                        {{ext}}<span v-if="allowedExtensions.length !== index + 1">,</span>
                    </span>
                </div>
            </b-alert>

            <!-- Save File Size - Validation errors -->
            <b-alert :show="validationByKey('SaveFileSize').length > 0"
                     variant="danger">
                <ul class="text-danger mb-0">
                    <li v-for="message in validationByKey('SaveFileSize')" :key="message">
                        {{ message }}
                    </li>
                </ul>
            </b-alert>
        </div>

        <div v-if="selectedFiles.length">
            <div class="d-flex justify-content-between align-items-center"
                 role="button"
                 @click="isSelectedFilesOpen = !isSelectedFilesOpen">
                <span :class="isSelectedFilesOpen ? 'isOpen' : 'isClosed'">
                    <font-awesome-icon class="mr-1" icon="chevron-right" /> Uppladdade filer
                </span>
                <b-badge variant="info" class="special-badge">
                    {{ selectedFiles.length }} st
                </b-badge>
            </div>

            <!-- File list -->
            <b-list-group v-show="isSelectedFilesOpen">
                <b-list-group-item v-for="file in selectedFiles"
                                   :key="file.id"
                                   class="d-flex justify-content-between align-items-center">
                    <a href="#" 
                       @click.prevent="openFile(file)"
                       :title="file.fileName.length > truncateLength ? file.fileName : ''">
                        {{ truncateFileName(file.fileName, '...')}}
                    </a>
                    <div>
                        <b-button v-if="!readOnly"
                                  variant="default"
                                  :disabled="isSubmitting"
                                  v-b-tooltip.hover.left
                                  :title="!file.description ? 'Lägg till beskrivning' : file.description"
                                  size="sm"
                                  @click.stop="addDescription(file)">
                            <font-awesome-icon :icon="!file.description ? 'comment-plus' : 'comment-lines'" />
                        </b-button>
                        <b-button v-if="!readOnly"
                                  variant="default"
                                  :disabled="isSubmitting"
                                  v-b-tooltip.hover
                                  :title="'Ta bort'"
                                  size="sm"
                                  @click.stop="remove(file.id)">
                            <font-awesome-icon icon="trash-alt" />
                        </b-button>
                    </div>
                </b-list-group-item>
            </b-list-group>

            <!-- Validation errors -->
            <b-alert :show="validationByKey('DeleteFile').length > 0"
                     variant="danger"
                     class="mt-2">
                <ul class="text-danger mb-0 list-unstyled">
                    <li v-for="message in validationByKey('DeleteFile')"
                        :key="message"
                        v-html="message" />
                </ul>
            </b-alert>
        </div>
        <div v-else
             class="mb-3">
            <div class="d-flex justify-content-between align-items-center">
                <span>
                    Uppladdade filer
                </span>
                <b-badge variant="info" class="special-badge" style="margin-bottom:0px;">
                    0 st
                </b-badge>
            </div>
        </div>

        <!-- Modal description form -->
        <b-modal :id="`file-uploader-modal--description-form-${_uid}`"
                 hide-footer>
            <template #modal-title>
                {{descriptionForm.description ? 'Ändra ' : 'Lägg till ' }} beskrivning
            </template>
            <b-form class="d-block text-center"
                    @submit.prevent="onSubmitFileDescriptionModalForm">
                <small class="text-right"><span :class="{'text-danger' : descriptionForm.description.length > 500}">{{descriptionForm.description.length}}</span>/500</small>
                <b-form-group :id="`modal--description-form-group-${_uid}`"
                              label=""
                              :label-for="`modal--description-${_uid}`">
                    <b-form-textarea :id="`modal--description-${_uid}`"
                                     v-model="descriptionForm.description"
                                     rows="3" />
                </b-form-group>

                <b-alert :show="validationByKey('Description').length > 0"
                         variant="danger"
                         class="mt-2">
                    <ul class="text-danger mb-0 list-unstyled">
                        <li v-for="message in validationByKey('Description')"
                            :key="message">{{message}}</li>
                    </ul>
                </b-alert>

                <div class="d-flex mt-3 justify-content-between">
                    <b-button variant="warning"
                              @click="onCloseFileDescriptionModalForm">
                        Avbryt
                    </b-button>
                    <b-button variant="success"
                              type="submit">Spara</b-button>
                </div>
            </b-form>
        </b-modal>
        <!-- ./ Modal description form -->
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { post, get, remove } from '@/helpers/api';
    import { service as ctrlService } from '@/services/do-control.service';
    import { getExtension } from '@/helpers/file.helper';

    export default {
        name: 'FileUploader',
        props: {
            entityType: {
                type: Number,
                required: true
            },
            entityId: {
                type: Number,
                required: true
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            truncateLength: {
                type: Number,
                default: null,
                required: false
            }
        },
        data() {
            return {
                isSubmitting: false,
                isSelectedFilesOpen: false,
                files: [],
                selectedFiles: [],
                validationErrors: null,
                allowedExtensions: [],
                descriptionForm: {
                    id: null,
                    description: ''
                }
            };
        },
        computed: {
            ...mapState({
                control: (state) => state.control.active
            }),
            controlId() {
                if (!this.control)
                    return null;
                return this.control.id;
            },
            isControlCheckedOut() {
                if (!this.control)
                    return false;
                return this.control.checkedOut;
            }
        },
        watch: {
            entityId: {
                immediate: true,
                handler(newValue, oldValue) {
                    if (oldValue !== newValue && newValue > 0)
                        this.getSelectedFiles();
                }
            }
        },
        methods: {
            /* Modal description form */
            async onSubmitFileDescriptionModalForm() {
                if (!this.isControlCheckedOut) {
                    await post(`File`, `SetDescription`, this.descriptionForm)
                        .then(() => {
                            // Update file.
                            let fileToBeUpdated = this.selectedFiles.find(x => x.id === this.descriptionForm.id);
                            fileToBeUpdated.description = this.descriptionForm.description;
                            // Close.
                            this.onCloseFileDescriptionModalForm();
                        })
                        .catch(x => this.validationErrors = x.response.data.errors);
                } else {
                    await ctrlService.setFileDescription(this.controlId, this.descriptionForm)
                        .then(() => {
                            // Update file.
                            let fileToBeUpdated = this.selectedFiles.find(x => x.id === this.descriptionForm.id);
                            fileToBeUpdated.description = this.descriptionForm.description;
                            // Close.
                            this.onCloseFileDescriptionModalForm();
                        });
                }
            },
            onCloseFileDescriptionModalForm() {
                this.descriptionForm = { id: null, description: '' };
                this.validationErrors = null;
                this.$bvModal.hide(`file-uploader-modal--description-form-${this._uid}`);
            },
            /* ./ Modal description form */
            async addDescription(file) {
                this.descriptionForm = { id: file.id, description: file.description || '' };
                this.$bvModal.show(`file-uploader-modal--description-form-${this._uid}`);
            },
            async openFile(file) {
                if (!this.isControlCheckedOut)
                    await this.getFile(file.id);
                else
                    await this.getOfflineFile(file.file, file.fileClientType);
            },
            async getFile(id) {
                window.open(
                    `${process.env.VUE_APP_ROOT_API}File/Download/${id}`,
                    '_blank',
                    '');
            },
            async getOfflineFile(file, type) {
                switch (type) {
                    case 'blob':
                        const url = window.URL.createObjectURL(file);
                        window.open(url, '_blank');

                        window.URL.revokeObjectURL(url);

                        //var reader = new FileReader();
                        //reader.onloadend = function (e) {
                        //    window.open(reader.result);
                        //}
                        //reader.readAsDataURL(file);
                        break;
                    case 'base64':
                        //let base64window = window.open(`base64:${file}`, '_blank');
                        base64window.document.write(`<iframe width="100%" height="100%" style="border: none;" src="${encodeURI(file)}"></iframe>`);
                        break;
                }
            },
            async getSelectedFiles() {
                if (!this.isControlCheckedOut)
                    await get('File', `GetAllBy/${this.entityType}/${this.entityId}`)
                        .then(x => this.selectedFiles = x.data);
                else
                    await ctrlService.getFileByEntityTypeAndEntityId(this.entityType, this.entityId)
                        .then(x => this.selectedFiles = x);
            },
            async onFilesChange() {
                if (this.entityId === 0 || this.files.length === 0) return;

                await this.save();
            },
            async save(entityId) {
                this.validationErrors = null;
                this.isSubmitting = true;

                if (!this.isControlCheckedOut) {
                    // Append properties to form.
                    let data = new FormData();
                    this.files.forEach((file) => {
                        // Check if correct fileextension
                        data.append('files', file, file.name);
                    });

                    await post(
                        'File',
                        `Save?entityType=${this.entityType}&entityId=${entityId || this.entityId
                        }`,
                        data
                    )
                        .then((x) => {
                            this.getSelectedFiles();
                            this.isSubmitting = false;
                            this.files = [];
                            // Set selected file list to open.
                            this.isSelectedFilesOpen = true;
                        })
                        .catch((x) => {
                            if (x.response.status === 413) {
                                this.validationErrors = { SaveFileSize: ['Filen är för stor'] };
                            } else {
                                this.validationErrors = x.response.data.response.errors;
                                this.allowedExtensions = x.response.data.allowedExtensions;
                            }
                            if (this.files.length > this.validationByKey('SaveFile').length)
                                this.getSelectedFiles();

                            this.isSubmitting = false;
                            // Set selected file list to open.
                            this.isSelectedFilesOpen = true;
                        });
                } else {
                    await ctrlService.addFiles({
                        controlId: this.controlId,
                        entityType: this.entityType,
                        entityId: this.entityId,
                        files: this.files
                    });
                    this.files = [];
                    await this.getSelectedFiles();
                    this.isSubmitting = false;
                    // Set selected file list to open.
                    this.isSelectedFilesOpen = true;
                }
            },
            async remove(id) {
                this.validationErrors = null;
                this.isSubmitting = true;

                // Confirm.
                this.$bvModal
                    .msgBoxConfirm('Filen kommer att raderas', {
                        title: 'Är du säker?',
                        size: 'sm',
                        cancelVariant: 'primary',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'danger',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then((confirmed) => {
                        if (confirmed) {
                            if (!this.isControlCheckedOut) {
                                remove('File', `Remove/${id}`)
                                    .then((x) => {
                                        this.removeFileFromList(id);
                                        this.isSubmitting = false;
                                    })
                                    .catch((x) => {
                                        this.validationErrors = x.response.data.errors;
                                        this.isSubmitting = false;
                                    });
                            } else {
                                ctrlService.removeFile(this.controlId, id, this.entityId).
                                    then(() => {
                                        this.removeFileFromList(id);
                                        this.isSubmitting = false;
                                    })
                            }
                        } else this.isSubmitting = false;
                    })
                    .catch(() => {
                        this.isSubmitting = true;
                    });
            },
            removeFileFromList(id) {
                // Remove deleted item from selected files.
                const index = this.selectedFiles.findIndex(x => x.id === id);
                this.selectedFiles.splice(index, 1);
            },
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            },
            truncateFileName(fileName, suffix) {
                if (!this.truncateLength)
                    return fileName;

                const extension = getExtension(fileName);
                return fileName.length > this.truncateLength
                    ? fileName.substring(0, fileName.length - extension.length).substring(0, this.truncateLength - suffix.length) + suffix + extension
                    : fileName;
            }
        }
    };
</script>

<style scoped>
    .list-group-item {
        padding: 5px 12px;
    }

    .special-badge {
        font-size: 14px;
        padding: 6px 15px;
        margin-bottom: 6px;
    }
</style>

<style>
    .custom-file-input ~ .custom-file-label[data-browse]::after {
        content: 'Välj filer';
    }
</style>
